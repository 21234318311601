
import { defineComponent, ref, onMounted } from 'vue'
import { ElTable, ElTableColumn, ElPagination, ElInput } from 'element-plus'
import { Search } from '@element-plus/icons-vue'
import { useDebounceFn } from '@vueuse/core'

import { apiErrorNotification } from '@/core/helpers'
import { localeDate } from '@/core/utils'
import { usePagination, useSort, usePermissions } from '@/core/compositions'
import { routeNames } from '@/router'
import { EPermissions } from '@/types'

export default defineComponent({
  name: 'PermissionsView',

  components: {
    ElTable,
    ElTableColumn,
    ElPagination,
    ElInput,
  },

  setup() {
    const {
      total,
      page,
      onUpdatePage,
      onUpdateTotal,
      onUpdateTake,
      take,
      skip,
    } = usePagination(routeNames.permissions)
    const { sortKey, sortValue, updateSort } = useSort()
    const { getPermissionsPagination, permissions } = usePermissions()

    const loading = ref(true)
    const searchValue = ref(null)

    onMounted(() => {
      getData()
    })

    const getData = async () => {
      loading.value = true

      try {
        const permissionsPagination = await getPermissionsPagination({
          take: take.value,
          skip: skip.value,
          sortKey: sortKey.value as string,
          sortValue: sortValue.value as string,
          ...(searchValue.value
            ? { search: searchValue.value as unknown as string }
            : null),
        })

        onUpdateTotal(permissionsPagination.count)
      } catch (error) {
        console.error(error)
        apiErrorNotification(error)
      } finally {
        loading.value = false
      }
    }

    const onPageChange = (page: number) => {
      onUpdatePage(page)

      getData()
    }

    const onSizeChange = (size: number) => {
      onUpdateTake(size)

      getData()
    }

    const onSortChange = (sort: { prop: string; order: string }) => {
      updateSort(sort.prop, sort.order)

      getData()
    }

    const onSearchChange = useDebounceFn(() => {
      onUpdatePage(1)

      getData()
    }, 700)

    return {
      total,
      page,
      take,
      onPageChange,
      onUpdatePage,
      onSizeChange,
      localeDate,
      loading,
      permissions,
      onSortChange,
      Search,
      searchValue,
      onSearchChange,
      EPermissions,
    }
  },
})
